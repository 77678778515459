.ant-table {
  font-size: 12px;
  // padding: 10px;
  border-radius: 6px;

  .multi-project {
    svg {
      color: #475569;
      opacity: 0.5;
    }
  }

  .ant-table-thead {
    display: block;
    background: #e9e9e9;
  }

  .ant-table-tbody {
    max-height: 280px;
    overflow-y: auto;
    display: block;
    // word-break: break-all;
    word-break: break-word;
  }

  .ant-table-thead,
  .ant-table-tbody {
    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }

  .ant-table-thead > tr > th {
    background-color: transparent;
    font-size: 12px;
    font-family: "Font-Semibold";
    color: $tabsActiveColor;

    &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
        [colspan]
      ):before {
      width: 0px;
    }
  }

  .ant-table-tbody > tr > td {
    color: $baseTextColor;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-color: $CardBorderColor;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: 0;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 14px;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: $selectRowColor;
  }

  a {
    color: $linkColor;
    font-family: Font-Medium;
    // text-decoration: underline;
  }
  // .ant-table-container,
  // .ant-table-container table .ant-table-cell:last-child {
  //   text-align: left;
  // }

  .ant-table-column-sorter-down.active,
  .ant-table-column-sorter-up.active {
    color: $linkColor;
  }
}

.cmn-table {
  .ant-table-container table .ant-table-cell:nth-child(7) {
    text-align: right;
  }
  .ant-table-container table .ant-table-cell:nth-child(6) {
    text-align: right;
  }
  .ant-table-container table .ant-table-cell:nth-child(5) {
    text-align: right;
  }
  .ant-table-container table .ant-table-cell:nth-child(4) {
    text-align: right;
  }
  .ant-table-container table .ant-table-cell:nth-child(3) {
    text-align: right;
  }

  .ant-table-container table .ant-table-cell:nth-child(2) {
    text-align: right;
  }
}

.two-row {
  .ant-table-container table .ant-table-cell:last-child {
    text-align: right;
  }
}

.last-column-center {
  .ant-table-container table .ant-table-cell:last-child {
    text-align: center;
  }
}

.checkbox-table {
  // .ant-table-container,
  // .ant-table-container table > thead > tr:last-child th:last-child {
  //   text-align: left;
  // }

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background: $linkColor;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: $selectRowColor;
  }
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: $selectRowColor;
  }

  table tr td.ant-table-selection-column,
  table tr th.ant-table-selection-column {
    width: 50px;
    text-align: center;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $linkColor;
    border-color: $linkColor;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $linkColor;
  }

  .ant-checkbox-wrapper:first-child {
    margin-right: 40px;
  }
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.cmn-table .ant-table-thead > tr > th,
.cmn-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  border-right: none;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border: none;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table {
  border: none;
}
.status-tag {
  .ant-table .ant-table-container table .ant-table-cell:last-child {
    text-align: left;
    & > div {
      border: 1px solid $CardBorderColor;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      display: inline-block;
      padding: 6px 10px;
      @include font_size_color(12px, null, null, null, Font-Medium);
    }
  }
}
