.ehr-table{
  .ant-table-cell{
    &:first-child{
      width: 100px;
    }
  }
}
.ehr-integration-list{
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f5f9ff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  .ant-table-row-expand-icon{
    &:focus,&:hover{
      outline: none;
      box-shadow: none;
    }
  }
}
.ehr-connect{
  cursor: default;
}
.ehr-list-modal{
  .addClient-content{
    padding: 20px 20px 30px;
  }
}
.sandbox-button-skeleton{
  width: 100px !important;
  height: 36px !important;
}