@import "../../assets/sass/variables";

.autonomizeModal {
  .ant-modal-content {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    .ant-modal-body {
      padding: 0px;
      // max-height: calc(100vh - 100px);
      // overflow-y: auto;
    }

    .ant-modal-header {
      display: none;
    }

    .ant-modal-footer {
      display: none;
    }
  }

  .ant-modal-close {
    padding: 6px;
    &-x {
      color: $baseTextColor;
      font-size: 17px;
      &:hover {
        color: $linkColor;
      }
    }
  }

  // .ant-modal-close-x:hover {
  //   color: $linkColor;
  // }

  .ant-row {
    display: block;
  }

  .ant-form-item {
    margin-bottom: 30px;
    &-label > label {
      color: $baseTextColor;
      font-size: 14px;
      font-family: Font-Medium;
    }
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 3px;
    // background: $cardBgColor;
    color: $baseTextColor;
    border: 1px solid $borderColor;
    font-size: 14px;
    margin-top: 5px;
    padding: 10px 6px;
    border: 0.5px solid #c4c4c4;
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.05);
  }

  .ant-input-focused,
  .ant-input:focus {
    border-color: $linkColor;
    box-shadow: 0 0 0 2px rgb(59 112 205 / 20%);
  }
  .ant-input:hover {
    border-color: $linkColor;
  }

  .ant-input[disabled]:hover {
    border-color: #d9d9d9;
  }
  // .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  // .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  //   background: #222328;
  // }

  .ant-form-item:last-child {
    // text-align: center;
    padding-top: 20px;
  }
  .auto-complete {
    .ant-select {
      width: 100%;
      margin-bottom: 30px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      // color: #475569;
      // margin-top: 5px;
      padding: 10px 6px;
      height: 44px;
      border-radius: 3px;
      border: 1px solid #c4c4c4;
      margin-top: 12px;
      box-shadow: 0px 0px 26px rgb(0 0 0 / 5%);
    }
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 40px;
    }
  }
  &.edit-project {
    .addClient-content {
      max-height: calc(100vh - 150px);
      overflow-y: auto;
    }
  }

  .add-new-insight {
    .ant-form-item:last-child {
      text-align: right;
    }
  }

  .deleteConfirmation {
    .aiButton {
      min-width: 121px;
      max-width: 121px;
    }
  }
  .ant-input[disabled] {
    background: $baseColor;
    opacity: 0.5;
  }

  // .ant-form-item-explain-error {
  //   font-size: 12px;
  // }
}
.ant-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.ant-modal {
  top: initial;
  padding: 10px 0;
}
