.container {
  // color: $textPrimaryColor;
  width: 100%;
  min-height: 100vh;
}

main {
  color: $baseTextColor;
  height: calc(100% - 140px);
  background-color: var(--body-bg-color);
  border-radius: 0 0 0 10px;
  display: grid;
  grid-template-columns: 1fr 300px;
  margin-bottom: 6px;

  .main {
    &-aside {
      overflow-y: auto;
      padding: 1.875rem 1.25rem;
      background: rgba($color: #F5F5F6, $alpha: 0.4);
    }
    &-container {
      overflow-y: auto;
      padding: 1.875rem 20px;
    }
  }
}

.evidence-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  // margin-top: 20px;
  // background-color: $cardBgColor;
  border-radius: 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  // border: 0px solid $borderColor;
}

// --------Spin--------

.ant-spin {
  color: $linkColor;
  margin: 0 auto;
  display: block;
  margin: 10px 0;
}

.ant-spin-dot-item {
  background-color: $linkColor;
}

// ----------Carousel---------

.ant-carousel {
  margin-top: 20px;
}

.slick-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: 1.25rem;
  color: $linkColor;
  display: block !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: 0;
  z-index: 2;
  color: $linkColor !important;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: 0;
  z-index: 2;
  color: $linkColor !important;
}

.ant-carousel .slick-dots {
  margin-bottom: 0px;
  padding-top: 5px;
  position: static;
  li button {
    background: $linkColor;
  }
  li.slick-active button {
    background: $linkColor;
  }
}

.additional-data-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .section {
    margin-bottom: 20px;

    h3 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #4a4a4a;
    }
  }

  .ant-table {
    margin-top: 10px;
  }

  .ant-table-thead > tr > th {
    font-weight: 600;
  }

  .ant-table-tbody > tr:hover {
    background-color: #f5f5f5;
  }
}